import React, { useState, useEffect } from 'react';


const AktivnostDragDrop = ({ aktivnost }) => {

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');  // Mjeseci počinju od 0 u JS
        const day = String(date.getDate()).padStart(2, '0');

        return `${month}/${day}/${year}`;
    };

    return (
        <div className="aktivnost">

            <div className="aktivnost-title">
                <div className="title">
                    <h3>{aktivnost.title}</h3>

                </div>
                <span title="Drag" className="material-symbols-outlined icon_style-2">
                    drag_handle
                </span>
            </div>

            <div className="aktivnost-body">
                <span>{aktivnost.description}</span>
                <span>{aktivnost.status}</span>
                <span>{formatDate(aktivnost.toDate)}</span>
            </div>
        </div>
    )
}

export default AktivnostDragDrop;