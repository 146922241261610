import React, { useState, useEffect } from 'react';
import Aktivnost from './Aktivnost/Aktivnost';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import NavBar from '../../Elements/NavBar/NavBar';
import './Aktivnosti.scss'
import NovaAktivnost from './Aktivnost/NovaAktivnost';
import IzmeniAktivnost from './Aktivnost/IzmeniAktivnost';
import { apiURL } from '../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { closeDNDModal } from '../../../features/modal/modalSlice';
import { fetchAktivnosti } from '../../../features/aktivnosti/aktivnostiSlice';
import AktivnostiDragDrop from './AktivnostDragDrop/AktivnostiDragDrop';

const Aktivnosti = () => {
    const token = localStorage.getItem('jwtToken');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (token) {
            dispatch(fetchAktivnosti(token));
        }
    }, [token, dispatch]);
    const [aktivnosti, setAktivnosti] = useState([]);
    const [selectedAktivnostId, setSelectedAktivnostId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const showDNDModal = useSelector((state) => state.modal.showDNDModal);
    // const activitiesReorganize = useSelector((state) => state.aktivnosti);
    const { items: activitiesReorganize, status, error } = useSelector((state) => state.aktivnosti);
    const [currentAktivnost, setCurrentAktivnost] = useState(null);




    const [formData, setFormData] = useState({
        title: '',
        description: '',
        toDate: ''
    });

    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {




        let isTokenExpired = false;

        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (!token) {
                console.error("Token nije pronađen.");
                navigate('/');
                return;
            }
            // Provjerava je li token istekao
            if (decodedToken.exp < currentTime) {
                console.error("Token je istekao.");
                isTokenExpired = true;
            }
        } catch (error) {
            console.error('Greška pri dekodiranju tokena:', error);
            isTokenExpired = true;
        }

        if (isTokenExpired) {
            navigate('/');
            return;
        }

        axios.get(`${apiURL}/aktivnosti`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }

        })
            .then(response => {
                setAktivnosti(response.data);
            })
            .catch(error => {
                console.error('Došlo je do greške prilikom dobavljanja aktivnosti:', error);
            });
    }, [token, navigate]);


    const handleCheckboxChange = (activityId, currentStatus) => {
        const newStatus = !currentStatus;
        const now = new Date().toISOString().slice(0, 19).replace('T', ' ');

        axios.put(`${apiURL}/aktivnosti/${activityId}/status`, {
            status: newStatus,
            toDate: now
        }, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                // Osvežite listu aktivnosti ili ažurirajte status u trenutnom stanju
                setAktivnosti(prevAktivnosti => {
                    return prevAktivnosti.map(aktivnost => {
                        axios.get(`${apiURL}/aktivnosti`, {
                            headers: {
                                'Authorization': 'Bearer ' + token
                            }

                        })
                            .then(response => {
                                setAktivnosti(response.data);
                            })
                            .catch(error => {
                                console.error('Došlo je do greške prilikom dobavljanja aktivnosti:', error);
                            });
                        if (aktivnost.activityId === activityId) {
                            return {
                                ...aktivnost,
                                status: newStatus,
                                toDate: aktivnost.toDate,
                                endDate: aktivnost.endDate
                            };
                        }
                        return aktivnost;
                    });
                });
            })
            .catch(error => {
                console.error('Došlo je do greške prilikom ažuriranja statusa:', error);
            });
    };


    const handleDelete = (activityId) => {
        const isConfirmed = window.confirm("Potvrdite brisnje aktivnosti.");

        if (isConfirmed) {
            axios.delete(`${apiURL}/aktivnosti/${activityId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => {
                    if (response.data.success) {
                        // Ažuriranje state-a da se ukloni aktivnost bez reloadinga stranice
                        dispatch(fetchAktivnosti(token));
                        setAktivnosti(aktivnosti.filter(a => a.activityId !== activityId));
                    } else {
                        console.error('Greška pri brisanju aktivnosti.');
                    }
                })
                .catch(error => {
                    console.error('Došlo je do greške prilikom brisanja aktivnosti:', error);
                });
        }
    };

    const refreshActivityList = () => {
        axios.get(`${apiURL}/aktivnosti`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                setAktivnosti(response.data);
                dispatch(fetchAktivnosti(token));
            })
            .catch(error => {
                console.error('Došlo je do greške prilikom dobavljanja aktivnosti:', error);
            });
    }




    const handleAktivnostClick = (aktivnostId) => {
        setSelectedAktivnostId(aktivnostId);
    };

    const handleEdit = (aktivnost, activityId) => {
        setCurrentAktivnost(aktivnost);
        setSelectedAktivnostId(activityId);
        setShowEditModal(true);
    };

    const handleSave = (id, updatedData) => {
        // Logika za snimanje izmena
        setShowEditModal(false);
        refreshActivityList();
    };


    const handleCloseDNDModal = () => {
        dispatch(closeDNDModal());
    };

    const fetchActivities = async () => {
        try {
            const response = await axios.get(`${apiURL}/aktivnosti`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setAktivnosti(response.data);
        } catch (error) {
            console.error('Došlo je do greške prilikom dobavljanja aktivnosti:', error);
        }
    };



    return (
        <div className='aktivnosti-container'>
            <NovaAktivnost onNewActivityAdded={refreshActivityList} />
            <div className="aktivnosti-wrapper">
                <div className="planirane-aktivnosti">
                    <h2>Planirane aktivnosti</h2>
                    <AktivnostiDragDrop show={showDNDModal} onHide={handleCloseDNDModal} activitiesReorganize={activitiesReorganize} refreshActivities={fetchActivities} />
                    <div className="planirane-aktivnosti--content">

                        {aktivnosti.filter(a => !a.status).map(a => (
                            <Aktivnost
                                key={a.activityId}
                                aktivnost={a}
                                handleCheckboxChange={handleCheckboxChange}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                            />
                        ))}

                        <IzmeniAktivnost
                            key={Date.now()}
                            show={showEditModal}
                            onHide={() => setShowEditModal(false)}
                            aktivnost={currentAktivnost}
                            handleSave={handleSave}
                        />
                    </div>

                </div>
                <div className="izvrsene-aktivnosti">
                    <h2>Izvršene aktivnosti</h2>
                    <div className="izvrsene-aktivnosti--content">
                        {aktivnosti.filter(a => a.status).map(a => (
                            <Aktivnost
                                key={a.activityId}
                                aktivnost={a}
                                handleCheckboxChange={handleCheckboxChange}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                handleSubactivity={() => handleAktivnostClick(a.activityId)}
                            />
                        ))}
                        <IzmeniAktivnost
                            key={Date.now()}
                            show={showEditModal}
                            onHide={() => setShowEditModal(false)}
                            aktivnost={currentAktivnost}
                            handleSave={handleSave}
                        />
                    </div>

                </div>
            </div>

        </div>

    );
};

export default Aktivnosti;
