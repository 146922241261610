import React from 'react';

const Podaktivnost = ({ podaktivnost, handleDelete }) => {


  return (
    <div className='subactivity'>
      <div className='subactivity-header'>
        <h6>{podaktivnost.content}</h6>
      </div>
      <div className="subactivity-body">

        {
          podaktivnost.days === 0 && podaktivnost.hours === 0 && podaktivnost.minutes === 0 ?
            <div className="subactivity-info">
              <span>&nbsp;</span>
            </div> :
            <div className="subactivity-info">
              <span>{podaktivnost.days}d</span>
              <span>{podaktivnost.hours}h</span>
              <span>{podaktivnost.minutes}m</span>
            </div>
        }


        <div className="subactivity-options">
          <span className="material-symbols-outlined icon-style-2"
            title="Ukloni"
            onClick={() => handleDelete(podaktivnost.subactivityId)}
          >
            cancel
          </span>
        </div>
      </div>
    </div>


  );
};

export default Podaktivnost;