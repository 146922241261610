import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Signup from './components/Signup/Signup';
import Login from './components/Login/Login';
import Aktivnosti from './components/Content/Aktivnosti/Aktivnosti';
import ResetPassword from './components/ResetPassword/ResetPassword';
import RestartPassword from './components/RestartPassword/RestartPassword';
import WelcomePage from './pages/WelcomePage/WelcomePage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import AktivnostiPage from './pages/AktivnostiPage/AktivnostiPage';

function App() {
  return (

    <Router>
      <Routes>
        
          <Route path="/" element={<WelcomePage />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/aktivnosti" element={<AktivnostiPage />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/restartPassword" element={<RestartPassword />} />
          <Route path="/settings" element={<SettingsPage />} />
      </Routes>
    </Router>
  );
}

export default App;

