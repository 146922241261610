import React, { useState } from 'react';
import axios from 'axios';
import '../Aktivnosti.scss';
import Podaktivnosti from '../../Podaktivnosti/Podaktivnosti';
import { apiURL } from '../../../../config';

const Aktivnost = ({ aktivnost, handleCheckboxChange, handleDelete, handleEdit }) => {

    const [showSubactivityModal, setShowSubactivityModal] = useState(false);
    const [podaktivnosti, setPodaktivnosti] = useState([]);

    const token = localStorage.getItem('jwtToken');
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');  // Mjeseci počinju od 0 u JS
        const day = String(date.getDate()).padStart(2, '0');

        return `${month}/${day}/${year}`;
    };

    const handleSubactivity = (aktivnostId) => {
        axios.get(`${apiURL}/podaktivnosti/${aktivnostId}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                setShowSubactivityModal(true);
                setPodaktivnosti(response.data);
            })
            .catch(error => {
                console.error('Došlo je do greške prilikom dobavljanja aktivnosti:', error);
            });
    }

    return (
        <div className={`aktivnost ${aktivnost.priority ? 'priority-border' : ''}`}>

            <div className="aktivnost-title">
                <div className="title">
                    <h3>{aktivnost.title}</h3>

                </div>
                <span title="Podaktivnosti" className="material-symbols-outlined icon_style-2"
                    onClick={() => handleSubactivity(aktivnost.activityId)}>
                    stacks
                </span>
                <span title="Izmeni" className="material-symbols-outlined icon_style-2"
                    onClick={() => handleEdit(aktivnost)}>
                    edit
                </span>
                <span title="Obriši" className="material-symbols-outlined icon_style-2"
                    onClick={() => handleDelete(aktivnost.activityId)}>
                    delete</span>
                {
                    aktivnost.status === false ?
                        <span title="Prebaci u izvršene aktivnosti" className="material-symbols-outlined icon_style-2"
                            onClick={() => handleCheckboxChange(aktivnost.activityId, aktivnost.status)}>
                            add_task
                        </span> : <span title="Prebaci u planirane aktivnosti" className="material-symbols-outlined icon_style-2"
                            onClick={() => handleCheckboxChange(aktivnost.activityId, aktivnost.status)}>
                            remove_done
                        </span>
                }
            </div>

            <div className="aktivnost-body">
                <span>{aktivnost.description}</span>
                <span>{aktivnost.status}</span>
                <span>{aktivnost.status === false ? formatDate(aktivnost.toDate) : formatDate(aktivnost.endDate)}</span>
            </div>
            <Podaktivnosti
                key={Date.now()}
                aktivnost={aktivnost}
                show={showSubactivityModal}
                onHide={() => setShowSubactivityModal(false)}
                handleSubactivity={handleSubactivity}
                podaktivnosti={podaktivnosti}
                onNewSubactivityAdded={handleSubactivity}
            />
        </div>
    );
};

export default Aktivnost;