import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';
import NavBar from '../Elements/NavBar/NavBar';
import './ResetPassword.scss'
import { apiURL } from '../../config';

function ResetPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleResetPassword = (event) => {
        event.preventDefault();

        axios.post(`${apiURL}/reset-password`, { email })
            .then(response => {
                setMessage(response.data.message);
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data ? error.response.data.message : 'Došlo je do greške prilikom slanja zahteva za resetovanje lozinke..';
                setMessage(errorMessage);
            });
    };

    return (
        <div className="reset-pass">
            <NavBar />
            <div className='reset-pass--container'>
                {message && <Alert variant="danger">{message}</Alert>}
                <Form onSubmit={handleResetPassword} className='form-box'>
                    <Form.Group controlId="email">
                        <Form.Control type="email" placeholder="Unesite email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </Form.Group>


                    <Button type="submit" className='btn-prim'>
                        Pošalji mi link
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export default ResetPassword;