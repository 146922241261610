import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        showDNDModal: false,
    },
    reducers: {
        toggleModal: state => {
            state.showDNDModal = !state.showDNDModal;
        },
        closeDNDModal: (state) => {
            state.showDNDModal = false;
        },
    },
});

export const { toggleModal, closeDNDModal } = modalSlice.actions;

export default modalSlice.reducer;