import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import './DeleteProfile.scss'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { apiURL } from '../../config';

const DeleteProfile = () => {

    const navigate = useNavigate();
    const token = localStorage.getItem('jwtToken');
    const [errorMessage, setErrorMessage] = useState('');
    const [message, setMessage] = useState('');


    const deleteProf = (e) => {
        e.preventDefault();

        const isConfirmed = window.confirm("Brisanjem profila brišete sve skladištene podatke vezane za aktivnosti. Potvrdite brisanje.");

        if (isConfirmed) {
        axios.delete(`${apiURL}/deleteProfile`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                localStorage.removeItem('jwtToken');
                navigate('/')
            })
            .catch(error => {
                console.error('Greška:', error.response?.data?.error || 'Greška.');
                setErrorMessage(error.response?.data?.error || 'Greška prilikom brisanja.');
                setMessage('');
            });
        }
    };

    return (
        <div className="delete-profile">
            <h2>Brisanje profila</h2>
            <p>Sve vaše aktivnosti biće uklonjene sa sajta.</p>

            <div className="alert-part">
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                {message && <Alert variant="success">{message}</Alert>}
            </div>
            <div className='btn-prim' onClick={deleteProf}><span>Obriši profil</span></div>

        </div>
    );

}



export default DeleteProfile;