import React, { useState, useRef, useEffect } from 'react';

import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { apiURL } from '../../../../config';

const NovaAktivnost = ({ onNewActivityAdded }) => {
    const token = localStorage.getItem('jwtToken');
    const [lastActivity, setLastActivity] = useState();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        toDate: new Date().toISOString().split('T')[0] // postavlja današnji datum kao početnu vrijednost
    });

    const [isOpen, setIsOpen] = useState(false);
    const [notOpenYet, setNotOpenYet] = useState(false);
    const novaAktivnostRef = useRef(null); // Referenca za detektovanje klika izvan


    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = event => {
        if (novaAktivnostRef.current && !novaAktivnostRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleButtonClick = () => {
        setIsOpen(prev => !prev);
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const lastActivityIndex = await findLastActivity();
            const lastActivityIndexToAdd = lastActivityIndex + 1;
            // Kreiranje objekta sa svim podacima koji se šalju
            const dataToSend = {
                ...formData, // Proširivanje formData objekta sa svim njegovim poljima
                orderIndex: lastActivityIndexToAdd // Dodavanje orderIndex-a
            };
            // Ovdje pretpostavljam da šaljete podatke na endpoint '/aktivnosti'. Promenite ako je drugačije.
            const response = await axios.post(`${apiURL}/dodajAktivnost`, dataToSend, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });


            if (response.data.success) {
                // Ovdje možete dodati neke akcije nakon uspešnog dodavanja, kao što je osvežavanje liste aktivnosti ili prikaz obavještenja.
                onNewActivityAdded();
                setIsOpen(false);
            }
        } catch (error) {
            console.error("Greška prilikom dodavanja nove aktivnosti:", error);
        }

        // Resetujemo formu nakon uspešnog dodavanja
        setFormData({
            title: '',
            description: '',
            toDate: new Date().toISOString().split('T')[0]
        });
    };

    const findLastActivity = async () => {
        try {
            const response = await axios.get(`${apiURL}/findLastActivity`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data && response.data.orderIndex !== undefined) {
                return response.data.orderIndex;
            } else {
                console.log("Poslednja aktivnost nije pronađena ili nema orderIndex.");
            }
        } catch (error) {
            console.error('Greška pri pronalaženju poslednje aktivnosti:', error);
            // Obrada grešaka ili prikazivanje poruke korisniku
        }
    };


    return (
        <>
            {isOpen && <div className="overlay" onClick={handleClickOutside}></div>}
            <div className={`nova-aktivnost ${notOpenYet ? `${isOpen ? 'moveDown' : 'moveUp'}` : ''}`} ref={novaAktivnostRef}>
                <Form className='form-box' onSubmit={handleSubmit}>
                    <Form.Group controlId="activityTitle">
                        <Form.Control
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            placeholder="Unesite naziv aktivnosti"
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="activityDescription">
                        <Form.Control
                            as="textarea"
                            rows={5}
                            type="text"
                            maxLength="150"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder="Unesite opis aktivnosti"
                        />

                    </Form.Group>

                    <Form.Group controlId="activityDate">
                        <Form.Control
                            type="date"
                            name="toDate"
                            value={formData.toDate}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    <Button className='btn-prim btn-dodaj-aktivnost' type="submit">
                        Dodaj aktivnost
                    </Button>

                </Form>


                <div className="create-activity">
                    <div id="prikaz-aktivnosti" onClick={handleButtonClick}>
                        {isOpen ? <span className="material-symbols-outlined icon_style-1">expand_circle_up</span> : <span className="material-symbols-outlined icon_style-1" onClick={() => setNotOpenYet(true)}>
                            add_circle
                        </span>}

                    </div>
                </div>
            </div>
        </>
    );


};

export default NovaAktivnost;