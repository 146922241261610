import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import './AktivnostiDragDrop.scss';
import AktivnostDragDrop from './AktivnostDragDrop';
import { apiURL } from '../../../../config';

const AktivnostiDragDrop = ({ show, onHide, activitiesReorganize, refreshActivities }) => {

    const [aktivnosti, setAktivnosti] = useState(activitiesReorganize);
    const token = localStorage.getItem('jwtToken');


    useEffect(() => {
        setAktivnosti(activitiesReorganize);
    }, [activitiesReorganize]);

    const onDragEnd = (result) => {
        const { destination, source } = result;

        // Ako nema destinacije ili se pozicija nije promenila, izlazimo
        if (!destination || (destination.index === source.index)) {
            return;
        }

        // Kreiramo novi niz aktivnosti
        const newActivities = Array.from(aktivnosti);
        const [relocatedItem] = newActivities.splice(source.index, 1);
        newActivities.splice(destination.index, 0, relocatedItem);
        // Ažuriramo stanje sa novim redosledom aktivnosti
        setAktivnosti(newActivities);
    };

    const saveChanges = async () => {
        try {

            const updatedOrder = aktivnosti.map((a, index) => ({
                activityId: a.activityId,
                orderIndex: index
            }));
            await axios.post(`${apiURL}/updateActivitiesOrder`, updatedOrder, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            // Zatvorite modal nakon uspešnog čuvanja
            onHide();

            refreshActivities();
        } catch (error) {
            console.error('Došlo je do greške prilikom čuvanja reorganizacije:', error);
        }
    };


    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" className='dnd_modal' centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Reorganizacija Aktivnosti
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DragDropContext onDragEnd={onDragEnd} className="drop-topscroll">
                    <Droppable droppableId="aktivnosti" data-react-beautiful-dnd-scroll-container="auto" >
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                {aktivnosti && aktivnosti.length > 0 ? (
                                    aktivnosti.map((aktivnost, index) => (
                                        <Draggable key={aktivnost.activityId} draggableId={String(aktivnost.activityId)} index={index}>
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <AktivnostDragDrop aktivnost={aktivnost} />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                ) : (
                                    <div>Nema aktivnosti za prikaz</div>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Modal.Body>
            <Modal.Footer>
                <div className='btn-prim btn-dodaj-aktivnost' type="submit" onClick={saveChanges}>
                    Sačuvaj
                </div>
            </Modal.Footer>
        </Modal>
    );


}


export default AktivnostiDragDrop;