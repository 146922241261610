import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import './NavBar.scss';
import logoHeader from '../../../assets/logoes/pronadjiSebeLogo-1.svg';
import BurgerButton from './BurgerButton/BurgerButton';

const MobileNav = () => {
  const headerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (headerRef.current) {
      const visina = headerRef.current.offsetHeight;
      document.documentElement.style.setProperty('--visina-headera', `${visina}px`);
    }
  }, [headerRef]);

  const [isOpen, setIsOpen] = useState(false);
  const [clicked, setClicked] = useState(false);


  const changeStatus = () => {
    setIsOpen(!isOpen);
    setClicked(true);
  }



  const isUserLoggedIn = () => {
    const token = localStorage.getItem('jwtToken');
    if (!token) {
      return false;
    }

    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.exp > Date.now() / 1000;
    } catch (error) {
      return false;
    }
  };

  const [loggedIn, setLoggedIn] = useState(isUserLoggedIn());

  useEffect(() => {
    setLoggedIn(isUserLoggedIn());
  }, []);

  const isUserLoggedInNavigate = () => {
    if (!loggedIn) {
      navigate('/');
    } else {
      navigate('/aktivnosti');
    }
  };


  return (
    <nav className='mobile-nav' ref={headerRef}>
      <div className="logoContent" onClick={isUserLoggedInNavigate}><img className='logoHeader' src={logoHeader} alt='logo unmaze' /></div>
      <div className="navTitle"><h1>Dnevnik aktivnosti</h1><h6><span>powered by </span><a href='https://pronadjisebe.com/' target='_blank'>Pronadji sebe</a></h6></div>
      {loggedIn && (
        <div className="btnBurger-regular" onClick={() => changeStatus()}>
          <BurgerButton isOpen={isOpen} clicked={clicked} />
        </div>
      )}
    </nav>
  );
};

export default MobileNav;