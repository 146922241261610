import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './ChangePassword.scss';
import { apiURL } from '../../config';

function ChangePassword() {

    const token = localStorage.getItem('jwtToken');

    const navigate = useNavigate();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [message, setMessage] = useState('');


    const handleSubmit = (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setErrorMessage('Lozinke se ne poklapaju.');
            return;
        }

        if (newPassword.length < 6) {
            setErrorMessage('Lozinka mora imati najmanje 6 karaktera.');
            return;
        }

        axios.post(`${apiURL}/changePassword`, {
            oldPassword,
            newPassword
        }, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                setErrorMessage('');
                setMessage('Uspešno promenjena lozinka.');
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            })
            .catch(error => {
                console.error('Greška:', error.response?.data?.error || 'Greška.');
                setErrorMessage(error.response?.data?.error || 'Greška prilikom promene lozinke.');
                setMessage('');
            });
    };

    const handleForgottenPass = () => {
        navigate('/resetPassword');
    };

    return (
        <div className="change-password">
            <h2>Promenite vašu lozinku</h2>
            <Form className='form-box' onSubmit={handleSubmit}>

                <Form.Group controlId="oldPassword">
                    {/* <Form.Label>E-mail</Form.Label> */}
                    <Form.Control type="password" placeholder="Vaša stara lozinka" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                </Form.Group>

                <Form.Group controlId="newPassword">
                    <Form.Control type="password" placeholder="Unesite novu lozinku" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                </Form.Group>

                <Form.Group controlId="confirmPassword">
                    <Form.Control type="password" placeholder="Ponovo unesite novu lozinku" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </Form.Group>

                <Button className='btn-prim' type="submit">
                    Promeni lozinku
                </Button>
            </Form>
            <div className="alert-part">
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                {message && <Alert variant="success">{message}</Alert>}
            </div>

            <p className='forgotten-pass' onClick={handleForgottenPass}>Zaboravili ste lozinku?</p>
        </div>
    );
}

export default ChangePassword;