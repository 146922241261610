import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import { apiURL } from '../../../../config';

const IzmeniAktivnost = ({ show, onHide, aktivnost, handleSave }) => {
    // Dodajte formu i stanja za upravljanje ulazima u formi
    const token = localStorage.getItem('jwtToken');
    const [isEnabled, setIsEnabled] = useState(false);
    // const [showEditModal, setShowEditModal] = useState(false);
    const [formState, setFormState] = useState({
        title: '',
        description: '',
        toDate: ''
    });

    // A kada komponenta dobije "aktivnost" kao prop, ažurirajte "formState"
    useEffect(() => {

        if (aktivnost) {
            setIsEnabled(aktivnost.priority);
            setFormState({
                title: aktivnost.title || '',
                description: aktivnost.description || '',
                toDate: aktivnost.toDate ? aktivnost.toDate.slice(0, 10) : ''
                // Ostala polja...
            });

        }
    }, [aktivnost]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleClose = () => {
        setFormState({
            title: '',
            description: '',
            toDate: ''
        });
        onHide(); // Ovo obavesti roditeljsku komponentu da zatvori modal
    };

    const handleToggleChange = () => {
        setIsEnabled(!isEnabled);
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Sprečava defaultno ponašanje forme

        if (!formState.title.trim() || !formState.toDate) {
            alert('Naslov aktivnosti i datum su obavezan.');
            return;
        }

        const dataToSend = {
            ...formState, // Proširivanje formData objekta sa svim njegovim poljima
            priority: isEnabled // Dodavanje orderIndex-a
        };

        axios.put(`${apiURL}/aktivnosti-update/${aktivnost.activityId}`, dataToSend, {
            headers: {
                'Authorization': `Bearer ${token}`,
                // ostali potrebni headeri
            }
        })
            .then(response => {
                // Obradite uspešan odgovor
                handleClose();
                handleSave();
                // Ovde možete ažurirati UI ili state ako je potrebno
            })
            .catch(error => {
                // Obradite eventualnu grešku
                console.error("Došlo je do greške pri ažuriranju aktivnosti", error);
            });
    };


    if (aktivnost) {
        return (
            <Modal className='izmeni-aktivnost' show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Izmeni Aktivnost</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='form-class'>
                        <Form.Group className="mb-3 modal-form" controlId="formBasicEmail">
                            <div className="priority">
                                <span className='priority-label'>Prioritet: </span>
                                <label className="switch">
                                    <input type="checkbox" id="toggle-switch" checked={isEnabled} onChange={handleToggleChange} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <Form.Control
                                type="text"
                                name="title"
                                placeholder="Izmenite naslov aktivnosti"
                                onChange={handleInputChange}
                                value={formState.title}
                                required
                            />
                            <Form.Control
                                as="textarea"
                                name="description"
                                rows={5}
                                type="text"
                                onChange={handleInputChange}
                                value={formState.description}
                                maxLength="150"
                                placeholder="Izmenite opis aktivnosti"
                            />
                            <Form.Control
                                type="date"
                                name="toDate"
                                onChange={handleInputChange}
                                value={formState.toDate}
                                required
                            />
                        </Form.Group>
                        {/* Dugme za snimanje izmena */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-prim btn-dodaj-aktivnost' onClick={handleSubmit}>
                        Sačuvaj Izmenе
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
};

export default IzmeniAktivnost;
