import React from 'react';
import NavBar from '../../components/Elements/NavBar/NavBar';
import Aktivnosti from '../../components/Content/Aktivnosti/Aktivnosti';
import './AktivnostiPage.scss'

const AktivnostiPage = () => {


    return (
        <div className="aktivnosti">
            <NavBar />
            <Aktivnosti />
        </div>

    );
};

export default AktivnostiPage;