import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from '../../../features/modal/modalSlice';
import { useNavigate } from 'react-router-dom';
import './SideBar.scss';
import AktivnostiDragDrop from '../../Content/Aktivnosti/AktivnostDragDrop/AktivnostiDragDrop';


const SideBar = ({ isOpen }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navName = ['drag_and_drop', 'settings', 'logout']

    const [isModalOpen, setIsModalOpen] = useState(false);


    const handlePage = (navigationName) => {
        switch (navigationName) {
            case 'logout':
                localStorage.removeItem('jwtToken');
                navigate('/');
                break;
            case 'drag_and_drop':
                navigate('/aktivnosti');
                dispatch(toggleModal());
                break;
            default:
                navigate(`/${navigationName}`);
        }
    }

    return (
        <>
            <div className={`side-bar ${isOpen ? 'showSideBar' : 'hideSideBar'}`}>
                <div className="list-box">
                    <div className="list-box-element" onClick={() => handlePage(navName[0])}>
                        <span className="material-symbols-outlined icon_style-4">
                            drag_pan
                        </span>
                        <span>Drag and drop</span>
                    </div>
                    <div className="list-box-element" onClick={() => handlePage(navName[1])}>
                        <span className="material-symbols-outlined icon_style-4">
                            settings
                        </span>
                        <span>Settings</span>
                    </div>
                    <div className="list-box-element" onClick={() => handlePage(navName[2])}>
                        <span className="material-symbols-outlined icon_style-4">
                            logout
                        </span>
                        <span>Logout</span>
                    </div>

                </div>
            </div>
            <AktivnostiDragDrop
                key={isModalOpen}
                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
            />
        </>
    )
}

export default SideBar;