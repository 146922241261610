import React from "react";
import './LeftSideBar.scss';

const LeftSideBar = ({ data, handleComponent }) => {


    return (
        <div className="left-side-bar">
            {Object.entries(data).map(([label, details], index) => (
                <div key={index} className="list-box">
                    <div className="labels">{label}</div>
                    <div className="list-box-container">
                        {details.options.map((option, idx) => (
                            <div key={idx} className="list-box-element" onClick={() => handleComponent(details.componentName[idx])}>
                                <span className="material-symbols-outlined icon_style-4">
                                    {details.google_icon[idx]}
                                </span>
                                <span>{option}</span>
                            </div>
                        ))}
                    </div>

                </div>
            ))}
        </div>
    );
}

export default LeftSideBar;