import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import './Faq.scss'


const Faq = () => {


    return (
        <div className="faq-container">
            <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Da li je moguće sortiranje aktivnosti?</Accordion.Header>
                    <Accordion.Body>
                        Aktivnosti se sortiraju ručno. Klikom na "Hamburger menu" u gornjem desnom uglu, odabirom opcije Drag and drop. Otvara se modal dialog, tako da spaja sve Vaše aktivnosti. Planirane i izvršene. Jednostavnim prevlačenjem podešavate redosled aktivnosti. Klikom na dugme sačuvaj, na glavnom prozoru će se prikazati izmene.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Da li mogu da postavim prioritetne aktivnosti u odnosu na druge?</Accordion.Header>
                    <Accordion.Body>
                        Jednostavnim odabirom opcije "Edit" tj. olovčice, koji se odnosi na izmenu karakteristike željene aktivnosti. Prva od ponuđenih opcija je slajder dugme, koje nakon klika postavlja aktivnost kao prioritetnu. Sačuvajte izmene i uočićete da se ta aktivnost izdvaja od ostalih po crvenom okviru.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Kako da prebacim aktivnost iz planiranih u izvršene?</Accordion.Header>
                    <Accordion.Body>
                        Odabirom poslednje opcije "Prebaci u izvršene aktivnosti", aktivnost se automatski prebacuje u red izvršenih. Na isti način se vrši povratak u planirane ukoliko zatreba.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Kako funkcionišu podaktivnosti?</Accordion.Header>
                    <Accordion.Body>
                        Svaka aktivnost može imati svoje podaktivnosti. Brisanjem bilo koje aktivnosti, brišu se automatski sve njene podaktivnosti. Klikom na opciju "Podaktivnosti", otvara se modal, koji možete popunjavati, tako što ćete dodati ime podaktivnosti, a zatim opciono, po želji dodati vrijednosti za dan, sat ili dan, što se odnosi na vašu slobodnu procenu, koliko će ta aktivnost trajati.<br />
                        Ukoliko imate aktivnost koju ste nazvali Filmovi i u njoj podaktivnosti koje se odnose na konkretne filmove, kao neka lista filmova. Možete staviti koliko film traje, 1sat 45min. Na taj način imate preglet dužine trajanja podaktivnosti.<br />
                        Podaktivnosti se uklanjaju sa liste jednostavnim klikom na dugme u donjem desnom uglu sveke podaktivnosti. Ukoliko je rešena ili nije više potrebno da tu stoji.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Može li DNEVNIK AKTIVNOSTI da se koristi kao aplikacija za mobilni?</Accordion.Header>
                    <Accordion.Body>
                        DNEVNIK AKTIVNOSTI je web aplikacija. Ali možete je kao bilo koju web aplikaciju sačuvati na desktopu vašeg mobilnog uredjaja. Ukoliko npr. koristite Chrome za pretraživač, klikom na tri tačkice u gornjem desnom uglu odaberete opciju "Add to Home screen" ili "Dodaj na početni ektan", telefon će vam sačuvati lokaciju i preko ikonice će te vrlo lako moći da pristupite Vašim aktivnostima, jednostavnim logovanjem. 
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}

export default Faq;