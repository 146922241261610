import React, { useState } from 'react';
import './Login.scss'
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import NavBar from '../Elements/NavBar/NavBar';
import { apiURL } from '../../config';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();


    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(`${apiURL}/login`, { email, password })
            .then(response => {
                const token = response.data.token;
                localStorage.removeItem('jwtToken');
                localStorage.setItem('jwtToken', token);
                // Postavljamo token kao header za sledeći zahtev
                return axios.get(`${apiURL}/nekaRuta`, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                });
            })
            .then(response => {
                navigate('/aktivnosti');
                // Ovdje možete dodati logiku za preusmeravanje korisnika ili postavljanje auth tokena
            })
            .catch(error => {
                console.error('Greška pri prijavi:', error.response?.data?.error || 'Greška.');
                setErrorMessage(error.response?.data?.error || 'Greška pri prijavi.');
            });
    };

    const handleForgottenPass = () => {
        navigate('/resetPassword');
    };

    return (
        <div className="login">
            <NavBar />
            <div className='login-container'>
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                <Form onSubmit={handleSubmit} className='form-box'>
                    <Form.Group controlId="email">
                        {/* <Form.Label>Email adresa</Form.Label> */}
                        <Form.Control type="email" placeholder="Unesite email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </Form.Group>

                    <Form.Group controlId="password">
                        {/* <Form.Label>Lozinka</Form.Label> */}
                        <Form.Control type="password" placeholder="Lozinka" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </Form.Group>


                    <Button type="submit" className='btn-prim'>
                        Prijavi se
                    </Button>
                    <p className='forgotten-pass' onClick={handleForgottenPass}>Zaboravili ste lozinku?</p>
                </Form>
            </div>

        </div>
    );
};

export default Login;