import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiURL } from '../../config';


export const fetchAktivnosti = createAsyncThunk(
    'aktivnosti/fetchAktivnosti',
    async (token, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${apiURL}/aktivnostiDragDrop`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Korišćenje builder callback-a za extraReducers
export const aktivnostiSlice = createSlice({
    name: 'aktivnosti',
    initialState: {
        items: [],
        status: null,
        error: null,
    },
    reducers: {
        // vaši reduceri
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAktivnosti.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAktivnosti.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload;
            })
            .addCase(fetchAktivnosti.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload ? action.payload : action.error.message;
            });
    },
});

export default aktivnostiSlice.reducer;