import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../components/Elements/NavBar/NavBar';
import './WelcomePage.scss'

const WelcomePage = () => {

    const navigate = useNavigate();

    const handleSignUp = () => {
        navigate('/signup');
    };

    const handleLogIn = () => {
        navigate('/login');
    };

    return (
        <div className="welcome-page">
            <NavBar />
            <div className="welcome-page-container">
                <div className='form-box'>
                    <h2>Dobrodošli!</h2>
                    <form>

                        <div className='btn-prim' onClick={handleSignUp}><span>Sign Up</span></div>
                        <div className='btn-prim' onClick={handleLogIn}><span>Log In</span></div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default WelcomePage;