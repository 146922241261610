import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import NavBar from '../Elements/NavBar/NavBar';
import './RestartPassword.scss'
import { apiURL } from '../../config';

function RestartPassword() {
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const location = useLocation();

    function useQuery() {
        return new URLSearchParams(location.search);
    }

    const query = useQuery();
    const tokenFromUrl = query.get('token');
    const [token] = useState(tokenFromUrl || '');


    const handleRestart = (event) => {
        event.preventDefault();

        axios.post(`${apiURL}/restartPassword`, { token, newPassword })
            .then(response => {
                if (response.data.success) {
                    window.location.href = '#/login';
                } else {
                    setMessage('Nije uspelo restartovanje lozinke.');
                }
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data ? error.response.data.message : 'Došlo je do greške prilikom restartovanja lozinke.';
                setMessage(errorMessage);
            });
    };

    return (
        <div className="restart-pass">
            <NavBar />
            <div className='restart-pass--container'>
                {message && <Alert variant="danger">{message}</Alert>}
                <Form onSubmit={handleRestart} className='form-box'>
                    <Form.Group controlId="password">
                        <Form.Control type="password" placeholder="Unesite novi password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                    </Form.Group>


                    <Button type="submit" className='btn-prim'>
                        Promeni lozinku
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export default RestartPassword;