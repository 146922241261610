import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavBar from '../Elements/NavBar/NavBar';
import './Signup.scss';
import { apiURL } from '../../config';

const Signup = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setErrorMessage('Lozinke se ne poklapaju.');
            return;
        }
        if (username.length < 5) {
            setErrorMessage('Korisničko ime mora imati najmanje 5 karaktera.');
            return;
        }
        if (password.length < 6) {
            setErrorMessage('Lozinka mora imati najmanje 6 karaktera.');
            return;
        }

        // Ovde dodajte logiku za slanje podataka na server
        axios.post(`${apiURL}/signup`, {
            username,
            email,
            password
        })
            .then(response => {
                const token = response.data.token;
                localStorage.removeItem('jwtToken');
                localStorage.setItem('jwtToken', token);
                navigate('/aktivnosti');
            })
            .catch(error => {
                console.error('Došlo je do greške prilikom registracije:', error.response.data);
                // Ovde možete postaviti poruku o grešci koju vraća server
                setErrorMessage(error.response.data.error || 'Došlo je do greške prilikom registracije.');
            });
    };

    return (
        <div className='signup'>
            <NavBar />
            <div className='signup-container'>
                <Form className='form-box' onSubmit={handleSubmit}>
                    <Form.Group controlId="username">
                        {/* <Form.Label>Korisničko ime</Form.Label> */}
                        <Form.Control type="text" placeholder="Unesite korisničko ime" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </Form.Group>

                    <Form.Group controlId="email">
                        {/* <Form.Label>E-mail</Form.Label> */}
                        <Form.Control type="email" placeholder="Unesite e-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>

                    <Form.Group controlId="password">
                        {/* <Form.Label>Lozinka</Form.Label> */}
                        <Form.Control type="password" placeholder="Unesite lozinku" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </Form.Group>

                    <Form.Group controlId="confirmPassword">
                        {/* <Form.Label>Potvrdite lozinku</Form.Label> */}
                        <Form.Control type="password" placeholder="Ponovo unesite lozinku" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    </Form.Group>

                    <Button className='btn-prim' type="submit">
                        Registrujte se
                    </Button>
                </Form>
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            </div>
        </div>
    );
};

export default Signup;