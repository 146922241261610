import React, { useState, useEffect } from 'react';
// import Modal from 'react-bootstrap/Modal';
import { Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import Podaktivnost from './Podaktivnost';
import './Podaktivnosti.scss'
import { apiURL } from '../../../config';
// ... ostali importi

const Podaktivnosti = ({ aktivnost, podaktivnosti, show, onHide, onNewSubactivityAdded }) => {
    const token = localStorage.getItem('jwtToken');
    const aktivnostId = aktivnost.activityId;
    useEffect(() => {
    }, []);

    const [formData, setFormData] = useState({
        content: '',
        days: '',
        hours: '',
        minutes: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            const response = await axios.put(`${apiURL}/dodajPodaktivnost/${aktivnostId}`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

            if (response.data.success) {
                // Ovdje možete dodati neke akcije nakon uspešnog dodavanja, kao što je osvežavanje liste aktivnosti ili prikaz obavještenja.
                // onNewActivityAdded();
                // setIsOpen(false);
                onNewSubactivityAdded(aktivnostId);
            }
        } catch (error) {
            console.error("Greška prilikom dodavanja nove podaktivnosti:", error);
        }
    }

    const handleClose = () => {
        setFormData({
            content: '',
            days: '',
            hours: '',
            minutes: ''
        });
        onHide(); // Ovo obavesti roditeljsku komponentu da zatvori modal
    };

    const handleDeleteSubactivity = (subactivityId) => {
        axios.delete(`${apiURL}/subaktivnosti/${subactivityId}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success) {
                    // Ažuriranje state-a da se ukloni aktivnost bez reloadinga stranice
                    onNewSubactivityAdded(aktivnostId);
                } else {
                    console.error('Greška pri brisanju aktivnosti.');
                }
            })
            .catch(error => {
                console.error('Došlo je do greške prilikom brisanja aktivnosti:', error);
            });
    };

    return (
        <>
            <Modal className='subactivities' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Podaktivnosti
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body-1'>
                    {podaktivnosti.map((podaktivnost) => (
                        <Podaktivnost
                            key={podaktivnost.subactivityId}
                            podaktivnost={podaktivnost}
                            handleDelete={handleDeleteSubactivity}
                        />
                    ))}

                </Modal.Body>
                <Modal.Footer>
                    <Form className='form-class'>
                        <Form.Group className="mb-3 modal-form" controlId="formBasicEmail">
                            <Form.Control
                                type="text"
                                name="content"
                                placeholder="Nova podaktivnost"
                                value={formData.content}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="form-group-time">
                            <Form.Control
                                type='number'
                                name='days'
                                placeholder='dan'
                                min={0}
                                max={365}
                                value={formData.days}
                                onChange={handleInputChange}
                            />
                            <Form.Control
                                type='number'
                                name='hours'
                                placeholder='sat'
                                min={0}
                                max={24}
                                value={formData.hours}
                                onChange={handleInputChange}
                            />
                            <Form.Control
                                type='number'
                                name='minutes'
                                placeholder='min'
                                min={0}
                                max={60}
                                value={formData.minutes}
                                onChange={handleInputChange}
                            />
                            <span className="material-symbols-outlined icon_style-2" onClick={handleSubmit}>
                                check_circle
                            </span>
                        </Form.Group>
                    </Form>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Podaktivnosti;