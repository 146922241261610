import React, { useState } from "react";
import './SettingsPage.scss';
import NavBar from "../../components/Elements/NavBar/NavBar";
import LeftSideBar from "../../components/Elements/LeftSideBar/LeftSideBar";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import DeleteProfile from "../../components/DeleteProfile/DeleteProfile";
import Faq from "../../components/FAQ/Faq";

const SettingsPage = () => {

    const labels = ["Privatnost", "Info"];
    const options = ["Promena lozinke", "Brisanje profila", "FAQ"];
    const google_icon = ["key", "delete", "quiz"];
    const componentName = ['ChangePassword', 'DeleteProfile', "Faq"];
    const componentMapping = {
        'ChangePassword': ChangePassword,
        'DeleteProfile': DeleteProfile,
        'Faq': Faq
    }
    const [showComponent, setShowComponent] = useState();

    const handleComponent = (componentName) => {
        setShowComponent(componentName);
        return componentName;
    }

    const data = {
        [labels[0]]: {
            options: [options[0], options[1]],
            google_icon: [google_icon[0], google_icon[1]],
            componentName: [componentName[0], componentName[1]]
        },
        [labels[1]]: {
            options: [options[2]],
            google_icon: [google_icon[2]],
            componentName: [componentName[2]]
        }
    };

    const ComponentToRender = componentMapping[showComponent];

    return (
        <div className="settings-page">
            <NavBar />
            <div className="settings-page-container">
                <LeftSideBar
                    data={data}
                    componentName={componentName}
                    handleComponent={handleComponent} />
                {ComponentToRender ? <ComponentToRender /> : null}
            </div>

        </div>
    );
}


export default SettingsPage;